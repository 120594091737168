// The limit of resolved relationships is 100 Stories
// possible values for pages: all or page-name
const resolutions = [
  { name: 'cms-workplaces-logo-block.workplaces', pages: ['all'] },
  { name: 'cms-workplaces-logo-block.integrations', pages: ['all'] },
  { name: 'cms-award-badges.awards', pages: ['all'] },
  { name: 'cms-award-badges.award_pack', pages: ['all'] },
  { name: 'award-pack.awards', pages: ['all'] },
  { name: 'cms-header-media-ctas.badges', pages: ['all'] },
  { name: 'cms-resource-cards.resource_cards', pages: ['all'] },
  { name: 'cms-trust.testimonial', pages: ['all'] },
  { name: 'cms-quote-with-image.quote', pages: ['all'] },
  { name: 'cms-feature-highlight.quotes', pages: ['all'] },
  { name: 'g-stats-quote.quote', pages: ['all'] },
  { name: 'cms-2-cols-img-copy.quote', pages: ['all'] },
  { name: 'cms-header-with-carousel-quotes.quotes', pages: ['all'] },
  { name: 'cms-trust-with-workplaces.quotes', pages: ['all'] },
  { name: 'cms-trust-with-workplaces.workplaces', pages: ['all'] },
  { name: 'cms-trust-with-workplaces.stats', pages: ['all'] },
  { name: 'cro-workplaces.workplaces', pages: ['all'] },
  { name: 'cms-related-features.related_features', pages: ['all'] },
  { name: 'accordion-item.quote', pages: ['all'] },
  { name: 'cms-problems-solutions-cards.industries', pages: ['all'] },
  { name: 'cms-trust-carousel-quote.quotes', pages: ['all'] },
  { name: 'cms-pricing-features.plan', pages: ['all'] },
  { name: 'cms-pricing-features.features', pages: ['all'] },
  { name: `book-a-consultation.quote_REGION`, pages: ['all'] },
  { name: `cms-header.tags`, pages: ['all'] },
  { name: `cms-onpage-form-highlight.workplaces`, pages: ['all'] },
  { name: 'filters.features_tag', pages: ['all'] },
  { name: 'filters.industries_tag', pages: ['all'] },
  { name: 'filters.types_tag', pages: ['all'] },
  { name: 'cms-company-rating.ratingContent', pages: ['all'] },
  { name: 'add-on-block.add_on_card', pages: ['all'] },
  { name: 'page.header_buttons_desktop', pages: ['all'] },
  { name: 'page.header_buttons_mobile', pages: ['all'] },

  // Blog
  { name: 'resource-featured.resources', pages: ['blog-index'] },
  { name: 'cards-carousel.cards', pages: ['blog-index'] },
  { name: 'blog-post.related_articles', pages: ['blog-post'] },
  { name: 'blog-post.author', pages: ['blog-post'] },
  { name: 'blog-post.features_tag', pages: ['blog-post'] },
  { name: 'blog-post.industries_tag', pages: ['blog-post'] },
  { name: 'blog-post.types_tag', pages: ['blog-post'] },

  // Customer Stories
  { name: 'customer-story.industry', pages: ['customer-story'] },
  { name: 'customers-index.first_quote', pages: ['customer-story-index'] },
  {
    name: 'customers-index.second_quote',
    pages: ['customer-story-index'],
  },
  { name: 'customers-index.third_quote', pages: ['customer-story-index'] },
  {
    name: 'customers-index.customer.customer',
    pages: ['customer-story-index'],
  },

  // Learning Centre
  { name: 'learning-path.courses', pages: ['training-learning-path'] },
];

export default function getResolutions(page, region = 'us') {
  let result = '';
  let count = 0;
  resolutions.forEach((res, index) => {
    // check for specific pages
    if (page && (res.pages.includes(page) || res.pages.includes('all'))) {
      result += `${index > 0 ? ',' : ''}${res.name.replace('REGION', region)}`;
      count++;
    }
  });
  /* istanbul ignore else  */
  if (count <= 100) {
    return result;
  } else {
    console.error('Storyblok resolutions limit exceeded!');
  }
}
